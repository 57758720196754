import { MenuCTAs, MenuLabels } from "@/models";
import Whatsapp from "@/utils/whatsapp";
import productDetails, { ProductTab } from "./product-details.data";

export const PRODUCT_DETAILS_PREFIX = "tudo-sobre-argo";
export const PRODUCT_DETAILS_REF: MenuLabels = {
  id: "tudo-sobre-argo",
  label: "Tudo sobre o Argo",
  slug: PRODUCT_DETAILS_PREFIX,
  url: `/${PRODUCT_DETAILS_PREFIX}`,
  nestedPrefix: PRODUCT_DETAILS_PREFIX,
};

const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  if (!pDetails) {
    return [];
  }
  return pDetails.reduce((prev, item, index) => {
    return [
      ...prev,
      {
        id: `data-menu-details-${index}`,
        label: item.title,
        slug: `${item.slug}`,
        url: `${item.slug}`,
        nestedPrefix: PRODUCT_DETAILS_PREFIX,
        reference: PRODUCT_DETAILS_REF,
        tabId: item.id,
        isDetails: true,
      } as MenuLabels,
    ];
  }, [] as MenuLabels[]);
};

export const links = {
  reserve: "/",
  financiamento: "https://argo.fiat.com.br/monte.html#versao",
  monte: "https://argo.fiat.com.br/monte.html#versao",
  concessionarias: "https://www.fiat.com.br/concessionarias.html",
  whatsapp: Whatsapp.contact(),
  compre: "https://www.fiat.com.br/compre/fiat-argo.html",
  ofertas: "https://ofertas.fiat.com.br/?q=argo",
  fiat: "https://www.fiat.com.br/",
};

export const linksLabel = {
  financiamento: "Simule um financiamento",
  concessionaria: "Concessionárias",
  ofertas: "Ver ofertas",
};

export const RESERVE_TITLE = "Reserve o seu";
export const MONTE_TITLE = "Monte o seu";
export const COMPRE_TITLE = "Compre o seu";

export const notMenuAnchors: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: "notMenuAnchors-0",
    label: "",
    url: "/",
    slug: "sound-design",
  },
];

export const dataMenuLabels: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: "datamenu-0",
    label: "Fiat Argo",
    url: "/",
    slug: "",
  },
  {
    numberId: 1,
    id: "datamenu-banner",
    label: "Motor Firefly",
    url: "/banner",
    slug: "banner",
  },
  {
    numberId: 2,
    id: "datamenu-versoes",
    label: "Todas as versões",
    url: "/versoes",
    slug: "versoes",
  },
  {
    numberId: 5,
    id: "datamenu-banner-endurance",
    label: "Endurance",
    url: "/banner-endurance",
    slug: "banner-endurance",
  },
  {
    numberId: 3,
    id: "datamenu-fotos",
    label: "Fotos",
    url: "/fotos",
    slug: "fotos",
  },
  {
    numberId: 4,
    id: "datamenu-tudo-sobre",
    label: "Tudo sobre o Fiat Argo",
    url: "/tudo-sobre-argo",
    slug: "tudo-sobre-argo",
  },
  ...getProductDetails(productDetails),
];

export const dataMenuLabelDesktop: () => MenuLabels[] = () => [
  {
    numberId: 0,
    id: "datamenu-0",
    label: "Fiat Argo",
    url: "/",
    slug: "",
  },
  {
    numberId: 1,
    id: "datamenu-banner",
    label: "Motor Firefly",
    url: "/banner",
    slug: "banner",
  },
  {
    numberId: 2,
    id: "datamenu-versoes",
    label: "Todas as versões",
    url: "/versoes",
    slug: "versoes",
  },
  {
    numberId: 5,
    id: "datamenu-banner-endurance",
    label: "Endurance",
    url: "/banner-endurance",
    slug: "banner-endurance",
  },
  {
    numberId: 3,
    id: "datamenu-fotos",
    label: "Fotos",
    url: "/fotos",
    slug: "fotos",
  },
  {
    numberId: 4,
    id: "datamenu-tudo-sobre",
    label: "Tudo sobre o Fiat Argo",
    url: "/tudo-sobre-argo",
    slug: "tudo-sobre-argo",
  },
];

export const dataMenuButtons: MenuCTAs[] = [
  {
    id: "datamenubutton-1",
    label: MONTE_TITLE,
    url: links.monte,
  },
  {
    id: "datamenubutton-2",
    label: COMPRE_TITLE,
    url: links.compre,
  },
  {
    id: "datamenubutton-3",
    label: "Simule um financiamento",
    url: links.financiamento,
  },
  {
    id: "datamenubutton-4",
    label: "Concessionárias",
    url: links.concessionarias,
  },
  {
    id: "datamenubutton-5",
    label: "Ver ofertas",
    url: links.ofertas,
  },
];

export const dataMenuButtonsDesktop: MenuCTAs[] = [
  {
    id: "datamenubutton-1",
    label: COMPRE_TITLE,
    url: links.compre,
  },
  {
    id: "datamenubutton-2",
    label: "Monte o seu",
    url: links.monte,
  },
];
